<si-loading-spinner *ngIf="areSubscriptionsLoading"></si-loading-spinner>
<ng-container *ngIf="!areSubscriptionsLoading">
    <form [formGroup]="form" class="d-flex h-100">
    <si-form-container
        #formContainer
        [form]="form"
        [enableValidationHelp]="false">
        <div si-form-container-content>
        <si-form-item label="Project Name" [labelWidth]="labelWidth" [required]="true">
            <div class="form-control-has-icon w-100">
            <input type="text" id="name" class="form-control" formControlName="name" required minlength="3">
            <i class="invalid-icon element-cancel-filled"></i>
            </div>
        </si-form-item>

        <si-form-item label="Project Description" [labelWidth]="labelWidth" [labelWrap]="false">
            <input type="text" id="description" class="w-100 form-control" formControlName="description">
        </si-form-item>

        <si-form-item label="Business Unit" [labelWidth]="labelWidth" [labelWrap]="false">
            <input type="text" id="businessUnit" class="w-100 form-control" formControlName="businessUnit" [ngModel]="businessUnitForm">
        </si-form-item>

        <si-form-item label="Project Owners" [labelWidth]="labelWidth" [labelWrap]="false">
            <input type="text" id="projectOwners" class="w-100 form-control" formControlName="projectOwners">
        </si-form-item>

        <si-form-item label="Subscriptions" [labelWidth]="labelWidth" [required]="false" inputId="form-class-label">
            <select 
                multiple 
                class="form-control" 
                formControlName="subscriptions"
                [id]="'form-class-label'">
                <option *ngFor="let subs of cloudAccounts" value="{{subs.externalId}}">{{subs.name}} ({{subs.externalId}})</option>
            </select>
        </si-form-item>  
        </div>
        <div si-form-container-buttons>
        <button type="button" (click)="save()" [disabled]="!form.valid" class="btn btn-primary me-2">Save</button>
        <button type="button" (click)="cancel()" class="btn btn-secondary">Cancel</button>
        </div>
    </si-form-container>
    </form>                         
</ng-container>
