import { Component, ElementRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { siRouterFadeAnimation } from '@simpl/siemens-brand-ng/tools';
import { ConfigService } from './services/config.service';

@Component({
  selector: '<app-root>',
  templateUrl: './app.component.html',
  animations: [siRouterFadeAnimation]
})
export class AppComponent {
  version = "";

  constructor(private configService: ConfigService, private _elementRef: ElementRef){}

  ngOnInit(): void {
    this.configService.getConf().subscribe((data) => this.version = data.app_version);
    this._elementRef.nativeElement.removeAttribute("ng-version");
  }

  getRouteAnimation(outlet: RouterOutlet) {
    return (outlet.isActivated ? outlet.activatedRoute : null);
  }
}
