<si-navbar-primary [account]="userData" [accountItems]="[
    { title: 'Logout', icon: 'element-logout', link: 'logout'}
  ]" navAriaLabel="Primary">
  <si-navbar-item quickAction [item]="themeToggleQuickAction">
  </si-navbar-item>
  <si-navbar-item quickAction [item]="{ title: 'Help', icon: 'element-help', items: [
      { title: 'Wiz Dashboard', href: 'https://siemens.com/wiz', },
      { title: 'USS Documentation', href: 'https://clp.siemens.io/cnapp-service/how_tos/uss/' },
      { title: 'API Documentation', href: 'https://clp.siemens.io/cnapp-service/how_tos/uss_api/'},
      { title: 'Report Self Service Bug', href: 'https://code.siemens.com/my-cloud-security/iam-integration/-/issues/new'},
      { title: '-' },
      { title: 'Swagger & OpenAPI Doc', href: '#/api-doc'},
      { title: 'Version ' + version}
  ]}"></si-navbar-item>
</si-navbar-primary>