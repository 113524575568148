import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { Project } from '../models/project';

import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    
    url = protectedResources.apiUsers.endpoint;

    constructor(private http: HttpClient) { }
    getUser(email: string) {
        return this.http.get<User>(`${this.url}/SamlUser?email=${email}`);
    }

    getUsers() {
        return this.http.get<User[]>(this.url);
    }

    getProjectUsers(id: string) {
        return this.http.get<Project>(`${this.url}/${id}`);
    }

    postUser(user: User) {
        return this.http.post<User>(this.url, user);
    }

    addUserToProject(projectId: string, email: string) {
        return this.http.post<User>(`${this.url}/AddProjectAssignment?projectId=${projectId}&email=${email}`,"");
    }

    removeUserFromProject(projectId: string, email: string) {
        return this.http.post<User>(`${this.url}/RemoveProjectAssignment?projectId=${projectId}&email=${email}`,"");
    }

    deleteUser(id: number) {
        return this.http.delete(this.url);
    }

    editUser(user: User) {
        return this.http.put<User>(this.url, user);
    }

    createLoginAuditLog(eventType: string) {
        return this.http.post(`${this.url}/CreateLogInAuditLog?eventType=${eventType}`, "");
    }
}
