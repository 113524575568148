import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project, ProjectInput } from '../models/project';

import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    url = protectedResources.apiProjects.endpoint;

    constructor(private http: HttpClient) { }

    getProjects() {
        return this.http.get<Project[]>(this.url);
    }

    getProject(projectId: string) {
        return this.http.get<Project>(`${this.url}/${projectId}`);
    }

    createProject(projectInput: ProjectInput) {
        return this.http.post<Project>(this.url, projectInput);
    }

    updateProject(projectInput: ProjectInput) {
        return this.http.put<Project>(`${this.url}?projectId=${projectInput.id}`, projectInput);
    }

    archiveProject(projectId: string) {
        return this.http.delete<Project>(`${this.url}/Archive?projectId=${projectId}`);
    }

    updateAccessReview(projectId: string) {
        return this.http.get<Project>(`${this.url}/AccessReview?projectId=${projectId}`);
    }

}
