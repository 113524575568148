import { CloudAccount, CloudAccountInput } from "./cloudAccount";
import { User } from "./user";

export interface Project {
    id: string;
    name: string;
    description: string;
    businessUnit: string;
    projectOwners: User[];
    cloudAccountLinks: CloudAccount[];
    identifiers: string[];
    lastAccessReviewAt: string;
    lastAccessReviewBy: string;
    assignedUsersList: User[];
    isFolder: boolean;
    ancestorProjects?: Project[];
}

export class ProjectInput {
    id?: string;
    name!: string;
    description?: string;
    businessUnit?: string;
    projectOwners?: string[];
    cloudAccountLinks?: CloudAccountInput[];
    identifiers?: string[];
    isFolder: boolean | undefined;
    parentProjectId?: string;
}