import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CloudAccount } from '../models/cloudAccount';

import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class CloudAccountService {
    url = protectedResources.apiCloudAccounts.endpoint;

    constructor(private http: HttpClient) { }

    getCloudAccounts() {
        return this.http.get<CloudAccount[]>(this.url);
    }
}