import { LogLevel } from '@azure/msal-browser';

export const environment = {
  production: false
};

export const aadScopesUserImpersonation = "api://mycloudsecurity-ussdev/user_impersonation";
export const azureAdCLientId = "e584a3dc-5f6c-4b49-9d37-94b44c7defb9"
export const redirectUri = "https://uss-dev.mycloudsecurity.siemens.cloud/";
export const postLogoutRedirectUri = "https://uss-dev.mycloudsecurity.siemens.cloud/"
export const apiUrl = "https://api.uss-dev.mycloudsecurity.siemens.cloud/";
export const logLevel = LogLevel.Verbose;
export const PiiEnabled = false;
