import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { aadScopes } from '.././auth-config';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { ConfigService } from '../services/config.service';
import { UserService } from '../services/user.service';
import { MenuItem, selectMenuItemById, SiThemeService, ThemeType } from '@simpl/element-ng';
import { User } from '../models/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  isIframe = false;
  loginDisplay = false;
  userData: User | undefined;
  version = "";
  dataSource: any = [];
  helper = new JwtHelperService();
  private themeItems: MenuItem[] = [
    { id: 'auto', title: 'THEME_EDITOR.COLOR_SCHEMES.AUTO', action: () => this.switchTheme('auto') },
    { id: 'light', title: 'THEME_EDITOR.COLOR_SCHEMES.LIGHT', action: () => this.switchTheme('light') },
    { id: 'dark', title: 'THEME_EDITOR.COLOR_SCHEMES.DARK', action: () => this.switchTheme('dark') }
  ];

  private currentTheme = this.themeService.resolvedColorScheme;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService, 
    private configService: ConfigService,
    private userService: UserService,
    private themeService: SiThemeService) {
      this.switchTheme(this.currentTheme ?? 'auto');
      this.authService.initialize();
  }

  ngOnInit(): void {
    this.configService.getConf().subscribe((data) => this.version = data.app_version);
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => 
          msg.eventType === EventType.LOGIN_SUCCESS || 
          msg.eventType === EventType.SSO_SILENT_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.userService.createLoginAuditLog(result.eventType.toString()).subscribe({
          error: (error) => {
            
          }
        });
        this.setLoginDisplay();
      });

      if(this.authService.instance.getActiveAccount() !== null){
        this.setLoginDisplay();
      }else{
        this.login();
      }
  }

  login() {
    this.authService.ssoSilent({ scopes: aadScopes })
      .subscribe({
        error: (error) => {
          this.authService.loginRedirect({scopes: aadScopes})
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setLoginDisplay() {
    let account = this.authService.instance.getActiveAccount();
    this.userData = {
      id: String(account?.idTokenClaims?.["gid"]),
      name: account?.idTokenClaims?.["preferred_given_name"] + " " + account?.idTokenClaims?.["preferred_family_name"],
      title: account?.idTokenClaims?.["preferred_given_name"] + " " + account?.idTokenClaims?.["preferred_family_name"],
      company: String(account?.idTokenClaims?.["org_code"]),
      email: String(account?.username)
    };

    let currentUser = <User> { email: account?.username, name: account?.name}
    this.configService.setUser(currentUser);
    this.loginDisplay = this.userData?.title != "";
  }

  private switchTheme(theme: ThemeType) {
    this.themeService.applyThemeType(theme);
    this.themeItems = selectMenuItemById(this.themeItems, theme);
    this.themeToggleQuickAction.icon = this.themeService.resolvedColorScheme === 'dark' ? 'element-sun' : 'element-economy';
  }

  private toggleTheme(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.themeService.resolvedColorScheme === 'light' ? this.switchTheme('dark') : this.switchTheme('light');
  }

  themeToggleQuickAction = {
    title: 'APP.NAV.THEME',
    icon: 'element-sun',
    action: () => this.toggleTheme()
  };
}
