import { Component, ViewChild, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { SiFormContainerComponent } from '@simpl/element-ng';

import { ConfigService } from '../../services/config.service';
import { CloudAccountService } from '../../services/cloudAccount.service';
import { ProjectService } from '../../services/project.service';
import { User } from 'src/app/models/user';
import { CloudAccount, CloudAccountInput } from 'src/app/models/cloudAccount';
import { ProjectInput } from 'src/app/models/project';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  host: { class: 'p-5' }
})
export class CreateProjectComponent implements OnInit {

  currentUser: User | undefined;

  cloudAccounts: Array<CloudAccount> | undefined;

  businessUnitForm: String | undefined;

  areSubscriptionsLoading = false;

  form;
  tooltips = false;
  labelWidth = 140;

  ngOnInit() {
    this.getCloudAccounts();
    this.currentUser = this.configService.currentUser;
    this.businessUnitForm = this.currentUser?.name.substring(this.currentUser?.name.indexOf('(') + 1, this.currentUser?.name.indexOf(')'));
  }

  @ViewChild(SiFormContainerComponent) formContainer!: SiFormContainerComponent<typeof this.form.controls>;

  constructor(
    private configService: ConfigService, 
    private cloudAccountService: CloudAccountService,
    private projectService: ProjectService) {
    this.form = this.buildForm();
  }

  isValid(): boolean {
    return !this.form || this.form.valid;
  }

  trackByIndex = (index: number) => index;

  private buildForm() {
    return new FormGroup(
      {
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        description: new FormControl(''),
        businessUnit: new FormControl(''),
        projectOwners: new FormControl(['']),
        subscriptions: new FormControl([''])
      }
    );
  }

  getCloudAccounts(){
    this.areSubscriptionsLoading = true;
    this.cloudAccountService.getCloudAccounts().subscribe((accounts) => {
      if(accounts !== null && accounts.length >  0){
        this.cloudAccounts = accounts;
      }else{
      }
      this.areSubscriptionsLoading = false;      
    }); 
  }

  save() {
    if (!this.isValid()) {
      return;
    }

    let projectInput = new ProjectInput();

    projectInput.name = this.form.value.name!;
    projectInput.description = this.form.value.description!;
    projectInput.businessUnit = this.form.value.businessUnit!;
    projectInput.projectOwners = this.form.value.projectOwners!;
    projectInput.cloudAccountLinks = this.CreateCloudAccountType(this.form.value.subscriptions!);

    this.projectService.createProject(projectInput).subscribe({
        next: (newProject) => {
          if(newProject !== null){
            //Success
          }else{
            // Problem
          }
        },
        error: (err) => {
          //Auth Error etc,
        }
    });
  }

  cancel() {
    this.form.reset();
  }
  CreateCloudAccountType(subs: string[]) : CloudAccountInput[]{
    let accounts = new Array<CloudAccountInput>;

    subs.forEach( sub => {
      let account = new CloudAccountInput;
      account.cloudAccount = sub;
      accounts.push(account);
    })

    return accounts;
  }

}
