import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

    constructor(
        private authService: MsalService) {
      }

    ngOnInit(): void {
        this.logout();
    }

    logout() { // Add log out function here
        this.authService.logoutRedirect();
      }
}
