import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../models/configuration';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
  })
  export class ConfigService {

    currentUser: User | undefined;

    constructor(private http: HttpClient) { }
    
    setUser(user: User){
      this.currentUser = user;
    }

    getConf() {
     return this.http.get<Configuration>('configuration.json');
    }
  }