import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { apiUrl } from 'src/environments/environment';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-swagger-ui',
  templateUrl: './api-doc.component.html'
})
export class SwaggerUiComponent implements OnInit {

  constructor(
    private authService: MsalService,
    private router: Router) {
  }

  ngOnInit(): void {

    if(this.authService.instance.getActiveAccount() !== null){
      this.generateSwagger();
    }
    else{
      this.router.navigate(['/']);    
    }
    
  }

  generateSwagger() {
    SwaggerUIBundle({
      dom_id: '#swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      url: apiUrl + '/swagger/v1/swagger.json',
      docExpansion: 'none',
      operationsSorter: 'alpha'
    });
  }
}