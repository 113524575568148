import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectsManagementComponent } from './pages/projects-management/projects-management.component';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { SwaggerUiComponent } from './pages/api-doc/api-doc.component';

const routes: Routes = [
  { path: '', component: ProjectsManagementComponent, data: { title: 'My Cloud Security Project Management' } },
  { path: 'create-project', component: CreateProjectComponent, data: { title: 'Create a new My Cloud Security Project' } },
  { path: 'api-doc', component: SwaggerUiComponent, data: { title: 'API Docs' } },
  { path: 'logout', component: LogoutComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
