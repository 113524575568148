import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiLocalizationNgModule } from '@simpl/siemens-brand-localize-with-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';

import { 
  SiSelectModule, 
  SiMenuModule,
  SiContentActionBarModule,
  SiDatatableModule,
  SiSliderModule,
  SiEmptyStateModule,
  SiPaginationModule,
  SiDropdownModule, 
  SiNavbarModule, 
  SiLinkModule,
  SiFormModule ,
  SiLoadingSpinnerModule,
  SiAccordionModule,
  SiSidePanelModule,
  SiSearchBarModule,
  } 
  from '@simpl/element-ng';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { SiFooterModule } from '@simpl/siemens-brand-ng/footer';
import { SI_VALIDATION_ERROR_RESOLVER_MAP } from '@simpl/siemens-brand-ng/form-group';
import { SiHeaderModule } from '@simpl/siemens-brand-ng/header';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProjectsManagementComponent } from './pages/projects-management/projects-management.component';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { HeaderComponent } from './header/header.component';
import { LogoutComponent } from './pages/logout/logout.component';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalInterceptor, MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG, ProtectedResourceScopes } from '@azure/msal-angular';

import { msalConfig, protectedResources } from './auth-config';
import { ConfigService } from './services/config.service';
import { SimplElementNgModule } from '@simpl/element-ng';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.apiUsers.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiUsers.scopes.all]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiUsers.scopes.all]
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.apiUsers.scopes.all]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiUsers.scopes.all]
    }
  ]);

  protectedResourceMap.set(protectedResources.apiProjects.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiProjects.scopes.all]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiProjects.scopes.all]
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.apiProjects.scopes.all]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiProjects.scopes.all]
    }
  ]);

  protectedResourceMap.set(protectedResources.apiCloudAccounts.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiCloudAccounts.scopes.all]
    }
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  }
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ProjectsManagementComponent,
    CreateProjectComponent,
    HeaderComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    A11yModule,
    AppRoutingModule,
    SiHeaderModule,
    SiFooterModule,
    SiLoadingSpinnerModule,
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SiLocalizationNgModule,
    MsalModule,
    SiMenuModule,
    HttpClientModule,
    SiSearchBarModule ,
    SiContentActionBarModule,
    SiDatatableModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    SiFormModule, 
    SiSliderModule,
    SiPaginationModule,
    SiEmptyStateModule,
    SiSelectModule,
    SiDropdownModule,
    SimplElementNgModule,
    SiNavbarModule, 
    SiLinkModule,
    SiAccordionModule, 
    SiSidePanelModule,
    TranslateModule.forRoot(),
  ],
  providers: [
    {
      provide: SI_VALIDATION_ERROR_RESOLVER_MAP,
      useValue: { required: 'Value is required' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    ConfigService,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
