import { Component, OnInit, ViewChild, TemplateRef, inject  } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  SI_DATATABLE_CONFIG,
  Link,
  SiToastNotificationService,
  ToastStateName,
  SidePanelMode,
  SidePanelSize,
  DeleteConfirmationDialogResult,
  ConfirmationDialogResult, 
  SiActionDialogService,
  ModalRef, SiModalService, SelectOption
}
  from '@simpl/element-ng';
import { DatatableComponent, SelectionType, TableColumn, ColumnMode, SortType } from '@siemens/ngx-datatable';

import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/models/project';
import { User } from 'src/app/models/user';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';

import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

import { CloudAccountService } from '../../services/cloudAccount.service';


import { CloudAccount, CloudAccountInput } from 'src/app/models/cloudAccount';
import { ProjectInput } from 'src/app/models/project';


@Component({
  selector: 'app-projects-management',
  templateUrl: './projects-management.component.html'
})
export class ProjectsManagementComponent implements OnInit {

  @ViewChild(DatatableComponent) table!: DatatableComponent;
  @ViewChild(DatatableComponent) userTable!: DatatableComponent;
  @ViewChild(DatatableComponent) subsTable!: DatatableComponent;
  @ViewChild(DatatableComponent) projectSubsTable!: DatatableComponent;
  @ViewChild('contextCellTempl', { static: true }) contextCellTempl!: TemplateRef<any>;

  private subscription?: Subscription;

  SortType = SortType;

  tableConfig = SI_DATATABLE_CONFIG;
  projects = new Array<Project>();
  tempProjects = new Array<Project>();

  userRows = new Array<User>();
  tempUserRows = new Array<User>();
  projectLastAccessReviewedAt = "";
  projectLastAccessReviewedBy = "";

  cloudAccounts = new Array<CloudAccount>();
  subscriptionRows = new Array<CloudAccount>();
  tempSubRows = new Array<CloudAccount>();

  projectSubscriptionRows = new Array<CloudAccount>();
  projectTempSubRows = new Array<CloudAccount>();

  columns!: TableColumn[];
  subsColumns!: TableColumn[];
  projectSubsColumns!: TableColumn[];
  ColumnMode = ColumnMode;
  selectionType = SelectionType.single;
  subsSelectionType = SelectionType.checkbox;

  nextStatusType = 0;
  pageNumber = 0;
  pageSize = 10;
  subsPageNumber = 0;
  projectSubsPageNumber = 0;
  isLoading = false;
  isUsersLoading = false;
  hideWelcomeNoProjectsPanel = true;
  hideProjectsPanel = true;
  hideErrorPanel = true;
  projectId = "";
  emailOfUserToAdd = "";
  selectedProject: Project | undefined;
  selectedProjectParentId = "";
  isSelectedProjectAPersonalProject = false;
  selectedTabIndex = 0;
  folderProjects = new Array<Project>;


  collapsed = true;
  mode: SidePanelMode = 'scroll';
  size: SidePanelSize = 'wide';

  currentUser: User | undefined;

  businessUnitForm: String | undefined;

  areSubscriptionsLoading = false;

  newProjectOwners = [""];

  selected: any = [];
  selectedSubscriptions = new Array<CloudAccount>();
  notSelectedSubscriptions: any = [];

  isEditingProject = false;
  isAddingOwner = false;
  isFolderProject = false;

  createProjectForm;
  tooltips = false;
  labelWidth = 140;
  labelWidthDummy = 50;

  folderList: SelectOption[] = [];
  
  selectedFolder = "Root";

  initFolderList(): void {
    this.folderList = [
      { id: 'Root', title: 'Root' }
    ];
  }

  ngOnInit(): void {
    this.initTableColumns();
    this.initSubTableColumns();
    this.initProjectSubTableColumns();
    this.getProjects();
    this.currentUser = this.configService.currentUser;
  }

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private configService: ConfigService,
    private cloudAccountService: CloudAccountService,
    private toastNotificationService: SiToastNotificationService,
    private siModal: SiActionDialogService
  ) {
    this.createProjectForm = this.buildForm();
  }

  showToast(state: ToastStateName, title: string, message: string, disableAutoClose?: boolean,
    disableManualClose?: boolean, timeout?: number, action?: Link
  ) {
    this.toastNotificationService.showToastNotification({
      state, title, message, disableAutoClose, disableManualClose, timeout, action
    });
  }

  setFolder() {
    this.isFolderProject = !this.isFolderProject;
    this.selectedFolder = "Root";
  }

  getProject(id: string) {
    this.projectService.getProject(id)
      .subscribe({
        next: (project) => {
          if (project !== null) {
            this.projectSubscriptionRows = project.cloudAccountLinks;
            this.selectedProject!.cloudAccountLinks = project.cloudAccountLinks;
            this.projectTempSubRows = project.cloudAccountLinks;
          } else {
            this.showToast('warning', 'Error', 'Unable to Fetch Project Data from Wiz.');
          }
        },
        error: (err) => {
          this.showToast('warning', 'Error', err.error);
          this.isUsersLoading = false;
        }
      }
      );
  }

  getProjects() {
    this.isLoading = true;
    this.projectService.getProjects()
      .subscribe({
        next: (projects) => {
        if (projects !== null && projects.length > 0) {        
          this.projects = projects;
          this.tempProjects = [...projects]
          this.hideWelcomeNoProjectsPanel = true;
          this.hideProjectsPanel = false;
          this.initFolderList();
          this.setFolderProjects(); 
        } 
        else {
          this.hideWelcomeNoProjectsPanel = false;
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.showToast('warning', 'Error', err.error);
        this.isLoading = false;
        this.hideErrorPanel = false;
      }
    });
  }

  setFolderProjects() {
    this.projects.forEach(proj => {
      if(proj.isFolder) {
        this.folderList.push({id: proj.id, title: proj.name})
      }
    }) 
  }


  initSubTableColumns(): void {
    this.subsColumns = [
      {
        prop: 'name',
        name: 'Name',
        minWidth: 30,
        maxWidth: 250,
        resizeable: true,
        canAutoResize: true,
        checkboxable: true,
        sortable: true,
        headerCheckboxable: false
      },
      {
        prop: 'externalId',
        name: 'Subscription Id',
        minWidth: 40,
        maxWidth: 300,
        resizeable: true,
        sortable: true,
        canAutoResize: true,
      },
    ];
  }

  initProjectSubTableColumns(): void {
    this.projectSubsColumns = [
      {
        prop: 'name',
        name: 'Name',
        minWidth: 300,
        maxWidth: 500,
        resizeable: true,
        canAutoResize: true,
        checkboxable: false,
        headerCheckboxable: false
      },
      {
        prop: 'externalId',
        name: 'Subscription Id',
        minWidth: 400,
        maxWidth: 500,
        resizeable: true,
        canAutoResize: true,
      },
    ];
  }

  initTableColumns(): void {
    this.columns = [
      {
        prop: 'name',
        name: 'Project Name',
        minWidth: 100,
        resizeable: true,
        canAutoResize: true,
      },
      {
        prop: 'businessUnit',
        name: 'Business Unit',
        minWidth: 40,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'numSubscriptions',
        name: 'Subscriptions',
        minWidth: 10,
        cellClass: 'text-align-center-cell',
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'isFolder',
        name: '',
        minWidth: 10,
        resizeable: false,
        canAutoResize: false
      },
      {
        prop: 'context',
        name: '',
        width: 10,
        resizeable: false,
        canAutoResize: false,
        cellClass: 'text-align-center-cell',
        cellTemplate: this.contextCellTempl
      }
    ];
  }

  setProject(projects: Project[]) {
    this.selectedProject = projects[0];
    this.collapsed = false;
    this.isSelectedProjectAPersonalProject = this.selectedProject.identifiers.includes("MCS-Automation");
    this.getProject(this.selectedProject!.id);
    this.selectedProjectParentId = "None";

    if(this.selectedProject.ancestorProjects !== undefined && this.selectedProject.ancestorProjects?.length > 0) {
      this.selectedProjectParentId = this.selectedProject.ancestorProjects[0].id + " - " + this.selectedProject.ancestorProjects[0].name;
    }

    this.getUsers(this.selectedProject!.id);
  }

  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    // filter our data by Name, BU or Cloud Account Subscription Ids
    const tempProjects = this.tempProjects.filter(
      e =>
        e.name.toLowerCase().includes(val) ||
        e.businessUnit.toLowerCase().includes(val) ||
        e.cloudAccountLinks.some(cloudAccount => cloudAccount.externalId?.includes(val)) ||
        !val);

    // update the projects
    this.projects = tempProjects;

    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  updateUserFilter(event: any) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const tempProjects = this.tempUserRows.filter(e => e.email.toLowerCase().includes(val) || e.name.toLowerCase().includes(val) || !val);

    // update the projects
    this.userRows = tempProjects;

    // Whenever the filter changes, always go back to the first page
    this.userTable.offset = 0;
  }

  updateSubscriptionFilter(event: any) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const tempProjects = this.tempSubRows.filter(e => e.externalId.toLowerCase().includes(val) || e.name.toLowerCase().includes(val) || !val);

    // update the projects
    this.subscriptionRows = tempProjects;

    // Whenever the filter changes, always go back to the first page
    this.subsTable.offset = 0;
  }

  updateProjectsSubscriptionFilter(event: any) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const tempProjects = this.projectTempSubRows.filter(e => e.externalId.toLowerCase().includes(val) || e.name.toLowerCase().includes(val) || !val);

    // update the projects
    this.projectSubscriptionRows = tempProjects;

    // Whenever the filter changes, always go back to the first page
    this.projectSubsTable.offset = 0;
  }

  confirmRemoveUser(email: string) {
    this.subscription = this.siModal.showDeleteConfirmationDialog(
      'Do you want to remove ' + email + ' from ' + this.selectedProject!.name + '?',
      'Remove ' + email)
      .subscribe(confirmation => {
        switch (confirmation) {
          case DeleteConfirmationDialogResult.Delete:
            this.isUsersLoading = true;
            this.userService.removeUserFromProject(this.selectedProject!.id, email).subscribe({
              next: (response) => {
                this.showToast('success', 'Success', email + ' Removed from ' + this.selectedProject!.name + '.');
                this.getUsers(this.selectedProject!.id);
              },
              error: (err) => {
                this.showToast('warning', 'Error', err.error);
                this.isUsersLoading = false;
              }
            }
            );

            break;
          case DeleteConfirmationDialogResult.Cancel:
            break;
        }
      });
  }

  getUsers(projectId: string) {
    this.isUsersLoading = true;
    this.userService.getProjectUsers(projectId)
      .subscribe({
        next: (data) => {
          this.userRows = data.assignedUsersList
          this.tempUserRows = data.assignedUsersList;
          this.isUsersLoading = false;

          if(data.lastAccessReviewAt === null ) {
            this.projectLastAccessReviewedAt = "No Yet Reviewed";
          }else {
            this.projectLastAccessReviewedAt = new Date(parseInt(data.lastAccessReviewAt) * 1000).toDateString();
          }
          
          this.projectLastAccessReviewedBy = data.lastAccessReviewBy;
        },
        error: (err) => {
          this.showToast('danger', 'Error', 'Unable to get Users.')
          this.isUsersLoading = false;
        }
      });
  }

  getCloudAccounts() {
    this.areSubscriptionsLoading = true;
    this.cloudAccountService.getCloudAccounts().subscribe({
      next: (accounts) => {
        if (accounts !== null && accounts.length > 0) {
          this.cloudAccounts = accounts;
          this.subscriptionRows = accounts;
          if (this.selectedProject != null && this.isEditingProject) {
            this.selectedSubscriptions = [];  
            this.selectedProject!.cloudAccountLinks.forEach(acctLink => {
              let row = accounts.find(acct => acct.externalId === acctLink.externalId);
                if(row === undefined)
                {
                  this.notSelectedSubscriptions.push(acctLink);
                }
                else{
                 this.selectedSubscriptions.push(row);
                }
            });
          }

          this.selectedSubscriptions.forEach(subscription => {
            let index = this.subscriptionRows.findIndex(srow => srow.id == subscription.id)
            this.subscriptionRows.splice(index, 1);
          });

          this.subscriptionRows.unshift(...this.selectedSubscriptions);
          this.tempSubRows = this.subscriptionRows;

        }
        this.areSubscriptionsLoading = false;
      },
      error: (err) => {
        this.showToast('warning', 'Error', err.error);
        this.areSubscriptionsLoading = false;
      }
    });
  }

  addUserToProject() {
    this.isUsersLoading = true;
    if (this.userRows.some((user) => user.email === this.emailOfUserToAdd)) {
      this.showToast('info', 'Already a Member', this.emailOfUserToAdd + ' is already a member.')
      this.isUsersLoading = false;
      this.emailOfUserToAdd = '';
    }
    else {
      this.userService.addUserToProject(this.selectedProject!.id, this.emailOfUserToAdd).subscribe({
        next: (response) => {
          this.showToast('success', 'Success', response.email + ' Added to ' + this.selectedProject!.name + '.');
          this.getUsers(this.selectedProject!.id);
          this.emailOfUserToAdd = '';
        },
        error: (err) => {
          console.log(err);
          this.showToast('danger', 'Error: ' + err.error.title, err.error.detail)
          this.emailOfUserToAdd = '';
          this.isUsersLoading = false;
        }
      });
    }
  }

  setAccessReview() {
    this.subscription = this.siModal.showConfirmationDialog(
      'Have the granted access permissions have been reviewed and comply with the need-to-know principle for the project ' + this.selectedProject!.name + '?',
      'Confirm Access Review')
      .subscribe(confirmation => {
        switch (confirmation) {
          case ConfirmationDialogResult.Confirm:
            this.isUsersLoading = true;
            this.projectService.updateAccessReview(this.selectedProject!.id).subscribe({
              next: (response) => {
                this.showToast('success', 'Success', ' Access Review Updated for ' + this.selectedProject!.name + '.');
                this.getUsers(this.selectedProject!.id);
              },
              error: (err) => {
                this.showToast('warning', 'Error', err.error);
                this.isUsersLoading = false;
              }
            }
            );

            break;
          case ConfirmationDialogResult.Decline:
            break;
        }
      });
  }

  resizeTable() {
    setTimeout(() => this.table?.recalculate());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  toggle() {
    this.collapsed = !this.collapsed;
  }

  toggleMode() {
    this.mode = this.mode === 'over' ? 'scroll' : 'over';
  }

  changeSize() {
    this.size = this.size === 'regular' ? 'wide' : 'regular';
  }

  private buildForm() {
    return new FormGroup(
      {
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        description: new FormControl(''),
        businessUnit: new FormControl(''),
        newProjectOwner: new FormControl(''),
        subscriptions: new FormControl(['']),
        isFolder: new FormControl()
      }
    );
  }

  show: boolean = false;

  isValid(): boolean {
    return !this.createProjectForm || this.createProjectForm.valid;
  }

  trackByIndex = (index: number) => index;

  save() {
    if (!this.isValid()) {
      return;
    }

    if (this.selectedSubscriptions?.length === 0 && !this.isFolderProject) {
      this.showToast('warning', 'No Subscriptions Selected', "Please Select at least one Subscription.");
      return;
    }

    this.areSubscriptionsLoading = true;

    let projectInput = new ProjectInput();

    projectInput.name = this.createProjectForm.value.name!;
    projectInput.description = this.createProjectForm.value.description!;
    projectInput.businessUnit = this.createProjectForm.value.businessUnit!;
    projectInput.projectOwners = this.newProjectOwners;
    projectInput.isFolder = this.createProjectForm.value.isFolder === true ? true : false;
    projectInput.cloudAccountLinks = this.isFolderProject ? [] : this.CreateCloudAccountType(this.selectedSubscriptions!);
    projectInput.parentProjectId = this.selectedFolder == "Root" ? "" : this.selectedFolder

    this.projectService.createProject(projectInput).subscribe({
      next: (newProject) => {
        if (newProject !== null) {
          this.collapsed = true;
          this.ref?.hide();
          this.showToast('success', 'Success', 'Project ' + projectInput.name + ' created.');
          this.getProjects();
        } else {
          this.areSubscriptionsLoading = false;
          this.showToast('danger', 'Error', 'Unable to create project.')
        }
      },
      error: (err) => {
        this.areSubscriptionsLoading = false;
        this.showToast('danger', 'Error', err.error["detail"]);
      }
    });
    this.createProjectForm.reset();
  }

  update() {
    if (!this.isValid()) {
      return;
    }

    this.areSubscriptionsLoading = true;

    let projectInput = new ProjectInput();
    projectInput.id = this.selectedProject?.id;
    projectInput.identifiers = this.selectedProject?.identifiers;
    projectInput.name = this.createProjectForm.value.name!;
    projectInput.description = this.createProjectForm.value.description!;
    projectInput.businessUnit = this.createProjectForm.value.businessUnit!;
    projectInput.projectOwners = this.newProjectOwners;
    projectInput.isFolder = this.selectedProject?.isFolder;
    projectInput.parentProjectId = this.selectedFolder == "Root" ? "" : this.selectedFolder
    if(!this.selectedProject?.isFolder)
    {
      projectInput.cloudAccountLinks = this.CreateCloudAccountType(this.selectedSubscriptions!);
    }

    this.projectService.updateProject(projectInput).subscribe({
      next: (newProject) => {
        if (newProject !== null) {
          this.ref?.hide();
          this.collapsed = true;
          this.showToast('success', 'Success', 'Project ' + projectInput.name + ' updated.');
          this.getProjects();
        } else {
          this.areSubscriptionsLoading = false;
          this.showToast('danger', 'Error', 'Unable to update project.')
        }
      },
      error: (err) => {
        this.areSubscriptionsLoading = false;
        this.showToast('danger', 'Error', err.error['detail']);
      }
    });
  }

  confirmProjectArchiving() {
    this.subscription = this.siModal.showDeleteConfirmationDialog('Do you want to archive ' + this.selectedProject?.name + '?', 'This action cannot be undone.', 'Archive')
      .subscribe(confirmation => {
        switch (confirmation) {
          case DeleteConfirmationDialogResult.Delete:
            this.projectService.archiveProject(this.selectedProject!.id).subscribe(
              (response) => {
                this.toggle();
                this.getProjects();
                this.showToast('success', 'Success', ' Project Archived.');
              },
              (err) => {
                this.showToast('danger', 'Error', err.error['detail']);
              }
            );
            break;
          case DeleteConfirmationDialogResult.Cancel:
            break;
        }
      });
  }

  cancel() {
    this.createProjectForm.reset();
    this.ref?.hide();
  }

  CreateCloudAccountType(subs: Array<CloudAccount>): CloudAccountInput[] {
    let accounts = new Array<CloudAccountInput>;

    subs.forEach(sub => {
      let account = new CloudAccountInput;
      account.cloudAccount = sub["id"];
      accounts.push(account);
    })

    return accounts;
  }

  private ref?: ModalRef<unknown>;
  private modalService = inject(SiModalService);

  isInputEmailFormat(email: string) {
    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    return regexExp.test(email);
  }

  AddOwnerToProject() {
    this.isAddingOwner = true;
    let email = this.createProjectForm.value.newProjectOwner!;
    const index: number = this.newProjectOwners.indexOf(email);

    // Check if User exists already in the current List.
    if (index > -1) {
      this.showToast('warning', 'Duplicate Owner', 'User already in list of owners.');
      this.createProjectForm.controls.newProjectOwner.reset();
      this.isAddingOwner = false;
      return;
    }

    // Check if input is in Email format.
    if (!this.isInputEmailFormat(this.createProjectForm.value.newProjectOwner!)) {
      this.showToast('warning', 'Invalid Email Format', 'Input not in a valid format.');
      this.isAddingOwner = false;
      return;
    }

    // Finally Check if the email exists for a user on Wiz.
    this.userService.getUser(this.createProjectForm.value.newProjectOwner!).subscribe({
      next: (wizProjectOwner) => {
        if (wizProjectOwner.id !== '') {
          this.newProjectOwners.push(wizProjectOwner.email);
          this.isAddingOwner = false;
        } else {
          this.isAddingOwner = false;
          this.showToast('warning', 'User Not Found', 'Unable to find User on Wiz.')
        }

      },
      error: (err) => {
        this.isAddingOwner = false;
        this.showToast('warning', 'User Not Found', 'Unable to find User on Wiz.')
      }
    });

    // Reset the Form input.
    this.createProjectForm.controls.newProjectOwner.reset();
  }

  RemoveFromNewOwners(email: string) {
    if (email !== this.currentUser?.email) {
      const index: number = this.newProjectOwners.indexOf(email);
      if (index !== -1) {
        this.newProjectOwners.splice(index, 1);
      }
    } else {
      this.showToast('warning', 'Unable to Remove Project Owner', "You can't remove yourself.");
    }
  }

  createProject(template: TemplateRef<any>) {
    this.selectedFolder = "Root";
    this.isEditingProject = false;
    this.isFolderProject = false;
    this.selectedSubscriptions = [];
    this.getCloudAccounts();
    this.currentUser = this.configService.currentUser;
    this.newProjectOwners = [""];
    this.newProjectOwners.pop();
    this.newProjectOwners?.push(this.currentUser!.email);
    this.createProjectForm.controls.businessUnit.setValue(this.currentUser!.name.substring(this.currentUser!.name.indexOf('(') + 1, this.currentUser?.name.indexOf(')')));
    this.createProjectForm.controls.newProjectOwner.reset();

    this.openModal(template, 'modal-lg');
  }

  openModal(template: TemplateRef<any>, modalClass?: string) {
    this.ref?.hide();
    this.ref = this.modalService.show(template, {
      ignoreBackdropClick: false,
      keyboard: true,
      animated: true,
      class: modalClass,
      ariaLabelledBy: 'create -project-modal'
    });
  }

  editProject(template: TemplateRef<any>) {
    this.selectedFolder = "Root";
    this.createProjectForm.reset();
    this.isFolderProject = this.selectedProject!.isFolder;
    this.isEditingProject = true;

    if (!this.selectedProject?.identifiers?.includes("MCS-Automation")) {

      this.createProjectForm.controls.name.setValue(this.selectedProject!.name!);
      this.createProjectForm.controls.businessUnit.setValue(this.selectedProject!.businessUnit!);
      this.createProjectForm.controls.description.setValue(this.selectedProject!.description);
      let currentOwners = this.selectedProject!.projectOwners.map(owner => owner.email);
      this.newProjectOwners = currentOwners!;
      if(this.selectedProject!.ancestorProjects!.length > 0)
      {
        this.selectedFolder = this.selectedProject!.ancestorProjects![0].id
      }
      this.getCloudAccounts();
      this.openModal(template, 'modal-lg')
    }
    else {
      this.showToast('warning', 'Project Cannot Be Edited', "You can't edit a Personal Project.");

    }
  }
  
}
